
import './App.css';
import React from 'react';

import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


import balken from './assets/balken.svg'
import balkentwint from './assets/balkentwint.svg'
import balkenbank from './assets/balkenbank.svg'
import logo from './assets/logo.svg'
import blob from './assets/blob.svg'
import qr from './assets/qr.png'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';


function App(props) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [status, setStatus] = React.useState('23342.22');
  const { width, height } = useWindowSize()

  let percentStatus;

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClickClose = () => {
    console.log('works')
    setOpen(false);
  };

  let useEffect = () => {
    setStatus('360.00')
  }




  return (
    <div className="App">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className="header">
            <img src={logo} className="logo"/>
            <div className="textContainer"> 
              <h1>gisch eus de Rest?</h1>
              <a href="#spenden" className="spenden">
                <img src={blob} className="blob"/>
                <p>Jetzt spenden</p>
              </a>
              <p>
                <b>Das kleinLaut hatte dieses Jahr leider kein Glück mit dem Wetter.</b><br /> An beiden Festivaltagen hat es fast pausenlos geregnet.<br />Trotz tollen Acts, feinem Food und 1A-Infrastruktur fehlten uns zirka die Hälfte der Besucher*innen.<br />
                Da wir den grössten Teil des kleinLaut-Festivals über unsere Bar und Gastronomie finanzieren, treffen uns die Umsatzeinbussen stark.<br /> Auch unsere Reserven aus besseren Jahren reichen nicht aus, um alle Lieferant*innen zu bezahlen. <br />
              </p>  
              <h4>
                <b>Um alle Rechnungen zahlen zu können, fehlen uns CHF 17835.45 auf dem Konto.</b> <br />
              </h4>
                <h3>Nun habt ihr die Wahl:</h3><p>Gebt ihr uns den Rest? Oder gebt ihr uns den Rest? <br />Jeder Beitrag hilft, unsere Kosten zu decken und unsere Lieferant*innen fair bezahlen zu können. <br />
              </p>
              <p><b>Vielen Dank für eure Unterstützung!</b></p>
              <p>André, Carla, Edith, Felix, Hilke, Johannes, Karin, Karl, Lea, Lisa, Noa, Nienke, Eliane und Sarah ♡</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="barContainer">
            <h2 id="spenden" className="ziel">Unser Ziel</h2>
            
            <div className="bar">
              <h2>CHF 17835.45</h2>
              <div className="filled" style={{backgroundImage: 'url('+balken+')', width: Math.round(100/17835.45*status)+"%"}}></div>
            </div>
              <p className="status"><b>Aktuell: CHF {status} ({Math.round(100/17835.45*status)}%) erreicht</b></p>
          </div>

          
        </Grid>
        <Grid item xs={12} md={6}>
          <a href="https://pay.raisenow.io/rkjty" target="_blank">
            <div className="twint">
              <div className="movingBackground">
                <img src={balkentwint} />
                <img src={balkentwint} />
                <img src={balkentwint} />
                <img src={balkentwint} />
              </div>
              <h2>TWINT spenden</h2>
            </div>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a onClick={handleClickOpen}>
            <div className="bank">
              <div className="movingBackground">
                <img src={balkenbank} />
                <img src={balkenbank} />
                <img src={balkenbank} />
                <img src={balkenbank} />
              </div>
              <h2>IBAN / QR-Rechnung</h2>
            </div>
            <Dialog
              fullWidth={fullWidth}
              open={open}
              onClose={handleClickClose}>
              <DialogTitle>Bankangaben</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClickClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <img src={qr} className="img"/>
                <p>
                    <b>Verein kleinLaut</b><br />
                      Rebmoosweg 53<br />
                      5200 Brugg AG<br />
                      CH22 8080 8008 7340 1463 5<br />
                      IID (BC-Nr.): 80808<br />
                      SWIFT-BIC: RAIFCH22<br />
                </p>
              </DialogContent>
            </Dialog>
          </a>
        </Grid>
        <Grid item xs={12}>
          <div className="faq">
            <h2>FAQ</h2>
            <div className="Accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Ich brauche eine Quittung / Rechnung</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Kein Problem! Melde dich gerne unter finanzen@kleinlautfestival.ch bei uns.<br />
                    <b><u>Wichtig:</u> </b>Wir können dir nur eine «normale» Quittung/Rechnung ausstellen. Da wir nicht offziell als Gemeinnützig anerkannt sind, dürfen wir keine Spendenbescheinungen ausstellen.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Was passiert wenn das Ziel nicht erreicht wird?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Sollten wir nicht den gesamten Betrag zusammenbekommen, bleibt dein Beitrag trotzdem bestehen und hilft uns dabei die noch offenen Rechnungen so gut es eben geht zu decken.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Was passiert, wenn mehr Geld als nötig gespendet wird?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Den aktuellen Stand siehst du jederzeit auf unserer Website, falls mehr Geld gespendet wird, freuen wir uns sehr über eure Unterstützung und verwenden dies für die Finanzierung des nächsten Festivals.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
           


          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="footer">
            <div className="textContainer"><br/>
              <h2>Aftermovie 2023</h2>
              <div style={{padding: "56.25% 0 0 0", position:"relative"}}><iframe src="https://player.vimeo.com/video/860645109?h=7072333933&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position: "absolute", top: "0", left:"0", width:"100%",height:"100%"}} title="Kleinlaut Aftermovie V1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div> 
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="footer">
            <div className="textContainer"><br/>
              <a href="https://kleinlautfestival.ch" target="_blank">Verein kleinLaut</a> | <a href="mailto:info@kleinlautfestival.ch">info@kleinlautfestival.ch</a>
            </div> 
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
